import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Track = () => {
  const runScript = () => {
    if (typeof window !== `undefined`) {
      var r,
        i = document.getElementsByTagName("script")[0]
      if (document.getElementById("aftership-jssdk")) return
      r = document.createElement("script")
      r.id = "aftership-jssdk"
      r.src = "https://button.aftership.com/all.js"
      i.parentNode.insertBefore(r, i)
    }
  }
  return (
    <Layout>
      <SEO title="Track Your Order" />

      <section className="section">
        <div className="container">
          <div className="columns is-centered">
            <div className="column ">
              <h1 className="is-size-1 has-text-centered">Track Your Order</h1>
              <hr />
              <center>
                <div id="as-root"></div>
                <div
                  className="as-track-button"
                  data-size="large"
                  data-domain="waggtopia.aftership.com"
                ></div>
              </center>
            </div>
          </div>
        </div>
      </section>
      {runScript()}
    </Layout>
  )
}

export default Track
